import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  token: "",
  route: "",
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState: initialState,
  reducers: {
    getAuth: (state, action) => {
      state.token = action.payload.token;
      state.route = action.payload.route;
    },
  },
});

export const { getAuth } = authSlice.actions;
export default authSlice.reducer;
