import React from 'react';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
const antIcon = <Loading3QuartersOutlined style={{ fontSize: 20, color:"#0088ff" }} spin />;
const LoadingComponent = () => {
  return (
    <div className="loading-component">
      <Spin size="large" indicator={antIcon}/>
    </div>
  );
};

export default LoadingComponent;