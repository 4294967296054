import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import LoadingComponent from "./../../component/LoadingComponent";
import { useSelector } from "react-redux";

const FlashScreen = () => {
  const auth = useSelector((state) => state.authInfo);
  const [directLink, setDirectLink] = useState("");
  useEffect(() => {
    let redirectLink = "";
    if (auth && auth.token && auth.route) {
      localStorage.setItem("token", auth.token);
      if (auth.route) {
        redirectLink = "/" + auth.route;
      } else {
        redirectLink = "/duoi-hinh-bat-chu";
      }
      setDirectLink(redirectLink);
    }
  }, [auth]);

  return (
    <>
      {auth && directLink ? <Redirect to={directLink} /> : <LoadingComponent />}
    </>
  );
};

export default FlashScreen;
