import React, { useEffect, Suspense } from "react";
import "./App.css";
import {
  useHistory,
  withRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getAuth } from "./features/auth/authSlice";
import LoadingComponent from "./component/LoadingComponent";
import FlashScreen from "./features/flashScreen/FlashScreen";

const PlayGame = React.lazy(() => import("./features/game/PlayGame"));
const Home = React.lazy(() => import("./features/game/Home"));
const Congratulations = React.lazy(() =>
  import("./features/game/Congratulations")
);

function App(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    document.removeEventListener("message", () => {}, true);
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("POST_DATA");

    if ("addEventListener" in document) {
      document.addEventListener("DOMContentLoaded", function () {}, false);
    }
    //For iOS
    var iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      window.addEventListener(
        "message",
        function (e) {
          if (e.data !== "EXIT_COMMAND") {
            let { token, route, code } = JSON.parse(e.data);
            localStorage.setItem("token", token);
            localStorage.setItem("code", code);
            dispatch(getAuth({ token: token, route: route }));
          }
        },
        false
      );
    } else {
      //For Android
      document.addEventListener(
        "message",
        function (e) {
          if (e.data !== "EXIT_COMMAND") {
            let { token, route, code } = JSON.parse(e.data);
            localStorage.setItem("token", token);
            localStorage.setItem("code", code);
            let params = { token: token, route: route, code: code };
            dispatch(getAuth(params));
          }
          if (e.data === "EXIT_COMMAND") {
            if (history.location.pathname === "/duoi-hinh-bat-chu") {
              history.goBack();
            }
          }
        },
        false
      );
    }
  }, []);

  return (
    <div className="App w-100">
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to={"/flash-screen"} />}
          />

          <Route exact path="/flash-screen" component={FlashScreen} />
          <Route exact path="/duoi-hinh-bat-chu" component={Home} />
          <Route exact path="/play-game" component={PlayGame} />
          <Route exact path="/congratulations" component={Congratulations} />
        </Switch>
      </Suspense>
    </div>
  );
}
const mapDispatch2Props = { getAuth };
export default withRouter(connect(null, mapDispatch2Props)(App));
